import React, { useState, useEffect } from "react";
import { Moon, Sun, Users, Activity, Award } from 'lucide-react';
import UserProfile from "../assets/images/UserProfile.png";
import { EmployeeDetailsModal } from "../components/model-&-table/EmployeeDetailsModel";

const SearchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5 text-gray-400"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
    />
  </svg>
);

const ChevronDownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19 9l-7 7-7-7"
    />
  </svg>
);

const ViewIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5 text-gray-500 hover:text-green-600 cursor-pointer transition-colors"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
    />
  </svg>
);

const Dropdown = ({ options, value, onChange, isOpen, setIsOpen }) => (
  <div className="relative">
    <button
      onClick={() => setIsOpen((prev) => !prev)}
      className="flex items-center gap-2 text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100 px-3 py-2 rounded-md bg-white hover:bg-gray-100 dark:hover:bg-gray-700"
    >
      {value}
      <ChevronDownIcon />
    </button>
    {isOpen && (
      <div className="absolute top-full left-0 mt-1 bg-white dark:bg-gray-800 border dark:border-gray-700 rounded-md shadow-lg z-10 min-w-[160px]">
        {options.map((option) => (
          <button
            key={option}
            onClick={() => {
              onChange(option);
              setIsOpen(false);
            }}
            className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-700 dark:text-gray-300"
          >
            {option}
          </button>
        ))}
      </div>
    )}
  </div>
);

const UserManagementDashboard = () => {
  const initialEmployees = [
    {
      id: 1,
      name: "Andrew Bojangles",
      status: "Active",
      phone: "+79000010101",
      registrationDate: "22/12/2024",
      country: "India",
      activity: "2 days ago",
      avatar: UserProfile,
      details: "Andrew has been working with the company for 5 years.",
    },
    {
      id: 2,
      name: "Sarah Connor",
      status: "Inactive",
      phone: "+79000010102",
      registrationDate: "22/12/2024",
      country: "India",
      activity: "1 day ago",
      avatar: UserProfile,
      details: "Sarah is currently on a sabbatical leave.",
    },
    {
      id: 3,
      name: "John Smith",
      status: "Active",
      phone: "+79000010103",
      registrationDate: "22/12/2024",
      country: "India",
      activity: "3 days ago",
      avatar: UserProfile,
      details: "John oversees software projects.",
    },
  ];

  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");
  const [countryFilter, setcountryFilter] = useState("All countrys");
  const [employees, setEmployees] = useState(initialEmployees);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [viewedEmployee, setViewedEmployee] = useState(null);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [deptDropdownOpen, setDeptDropdownOpen] = useState(false);
  const [theme, setTheme] = useState(
    localStorage.getItem('theme') || 
    (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')
  );

  // Theme Application Effect
  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
    localStorage.setItem('theme', theme);
  }, [theme]);

  // Theme Toggle Function
  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  const countrys = [
    "All countrys",
    ...new Set(initialEmployees.map((emp) => emp.country)),
  ];
  const statuses = ["All", "Active", "Inactive"];

  useEffect(() => {
    let filteredEmployees = initialEmployees;

    if (searchTerm) {
      filteredEmployees = filteredEmployees.filter((emp) =>
        Object.values(emp).some((value) =>
          String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    if (statusFilter !== "All") {
      filteredEmployees = filteredEmployees.filter(
        (emp) => emp.status === statusFilter
      );
    }

    if (countryFilter !== "All countrys") {
      filteredEmployees = filteredEmployees.filter(
        (emp) => emp.country === countryFilter
      );
    }

    if (sortConfig.key) {
      filteredEmployees.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key])
          return sortConfig.direction === "asc" ? -1 : 1;
        if (a[sortConfig.key] > b[sortConfig.key])
          return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
      });
    }

    setEmployees(filteredEmployees);
  }, [searchTerm, statusFilter, countryFilter, sortConfig]);

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  const StatsCard = ({ icon: Icon, label, value, backgroundColor, textColor }) => (
    <div className={`${backgroundColor} rounded-2xl shadow-lg p-4 transform transition-all duration-300 hover:scale-105 hover:shadow-xl`}>
      <div className="flex items-center justify-between mb-2">
        <div className="p-2 rounded-full bg-white/20">
          <Icon className={`${textColor} w-6 h-6`} />
        </div>
        <div className="flex flex-col">
        <h3 className={`text-sm font-medium ${textColor}`}>{label}</h3>
        <div className={`text-2xl font-bold ${textColor}`}>{value}</div>
        </div>
      </div>
      
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-200 dark:bg-gray-900 text-gray-900 dark:text-gray-100">
      {/* Theme Toggle Button */}
      {/* <div className="fixed top-4 right-4 z-50">
        <button 
          onClick={toggleTheme}
          className="bg-gray-200 dark:bg-gray-700 p-2 rounded-full hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors flex items-center"
        >
          {theme === 'light' ? (
            <>
              <Moon className="mr-2 w-5 h-5" /> Dark
            </>
          ) : (
            <>
              <Sun className="mr-2 w-5 h-5" /> Light
            </>
          )}
        </button>
      </div> */}

      <div className="container mx-auto px-4 py-8">
        {/* Header */}
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-gray-800 dark:text-gray-200 mb-2">User Management</h1>
          <p className="text-gray-600 dark:text-gray-400">Manage and track your employees effectively.</p>
        </div>

        {/* Stats Section */}
        <div className="grid grid-cols-3 gap-6 mb-8">
          <StatsCard 
            icon={Users}
            label="Total Employees" 
            value={initialEmployees.length} 
            backgroundColor="bg-blue-50 dark:bg-blue-900/20"
            textColor="text-blue-800 dark:text-blue-300"
          />
          <StatsCard
            icon={Activity}
            label="Active Employees"
            value={initialEmployees.filter((emp) => emp.status === "Active").length}
            backgroundColor="bg-green-50 dark:bg-green-900/20"
            textColor="text-green-800 dark:text-green-300"
          />
          <StatsCard
            icon={Award}
            label="Countries"
            value={new Set(initialEmployees.map((emp) => emp.country)).size}
            backgroundColor="bg-purple-50 dark:bg-purple-900/20"
            textColor="text-purple-800 dark:text-purple-300"
          />
        </div>

        {/* Search Section */}
        <div className="flex gap-4 mb-6">
          <div className="flex-1 relative">
            <input
              type="text"
              placeholder="Search employees..."
              className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-700"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="absolute left-3 top-1/2 -translate-y-1/2">
              <SearchIcon />
            </div>
          </div>
        </div>

        {/* Filters */}
        <div className="flex gap-4 mb-6">
          <Dropdown
            options={statuses}
            value={statusFilter}
            onChange={setStatusFilter}
            isOpen={statusDropdownOpen}
            setIsOpen={setStatusDropdownOpen}
          />
          <Dropdown
            options={countrys}
            value={countryFilter}
            onChange={setcountryFilter}
            isOpen={deptDropdownOpen}
            setIsOpen={setDeptDropdownOpen}
          />
        </div>

        {/* Table */}
        <div className="overflow-hidden rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800">
          <div className="grid grid-cols-7 gap-4 px-4 py-3 bg-gray-50 dark:bg-gray-900 text-sm text-gray-500 dark:text-gray-400 border-b dark:border-gray-700">
            {["User", "Status", "Phone", "Registration Date", "Country", "Activity", "Actions"].map((header, index) => (
              <div
                key={index}
                onClick={() => {
                  const sortKeys = ["name", "status", "phone", "registrationDate", "country", "activity"];
                  if (sortKeys[index]) handleSort(sortKeys[index]);
                }}
                className="cursor-pointer hover:text-green-600 dark:hover:text-green-400"
              >
                {header}
              </div>
            ))}
          </div>

          <div className="divide-y dark:divide-gray-700">
            {employees.map((employee) => (
              <div
                key={employee.id}
                className="grid grid-cols-7 gap-4 px-4 py-4 items-center hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                <div className="flex items-center gap-3">
                  <img
                    src={employee.avatar}
                    alt=""
                    className="w-10 h-10 rounded-full"
                  />
                  <span className="font-medium text-gray-900 dark:text-gray-100">{employee.name}</span>
                </div>
                <div
                  className={`
                  ${
                    employee.status === "Active"
                      ? "text-green-600 dark:text-green-400"
                      : "text-red-600 dark:text-red-400"
                  }
                  font-semibold
                `}
                >
                  {employee.status}
                </div>
                <div className="text-gray-900 dark:text-gray-100">{employee.phone}</div>
                <div className="text-gray-900 dark:text-gray-100">{employee.registrationDate}</div>
                <div className="text-gray-900 dark:text-gray-100">{employee.country}</div>
                <div className="text-gray-600 dark:text-gray-400">{employee.activity}</div>
                <div>
                  <button
                    onClick={() => setViewedEmployee(employee)}
                    className="text-gray-500 hover:text-green-600 dark:text-gray-400 dark:hover:text-green-400 transition-colors"
                  >
                    <ViewIcon />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Employee Details Modal */}
        {viewedEmployee && (
          <EmployeeDetailsModal
            employee={viewedEmployee}
            onClose={() => setViewedEmployee(null)}
          />
        )}
      </div>
    </div>
  );
};

export default UserManagementDashboard;