import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';
import UserProfile from "../assets/images/UserProfile.png"
import { useNavigate } from 'react-router-dom';

const Dropdown = ({ value, options, onChange, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative">
      <button
        type="button"
        onClick={() => !disabled && setIsOpen(!isOpen)}
        className="w-full flex items-center justify-between p-3 bg-gray-50 dark:bg-gray-700 rounded-lg border border-gray-200 dark:border-gray-600 text-left"
      >
        <span className="text-sm text-gray-900 dark:text-gray-100">{value}</span>
        <ChevronDown className="h-4 w-4 text-gray-400 dark:text-gray-300" />
      </button>
      
      {isOpen && !disabled && (
        <div className="absolute z-10 mt-1 w-full bg-white dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-600 shadow-lg">
          <div className="py-1">
            {options.map((option) => (
              <button
                key={option}
                className="w-full px-4 py-2 text-sm text-left text-gray-900 dark:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-700"
                onClick={() => {
                  onChange(option);
                  setIsOpen(false);
                }}
              >
                {option}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50">
    <div className="fixed inset-0 bg-black/50" onClick={onClose} />
    <div className="fixed top-20 right-8">
      <div className="bg-white dark:bg-gray-900 rounded-3xl overflow-hidden shadow-2xl">
        {children}
      </div>
    </div>
  </div>
  );
};

const ProfileSection = ({ isEditing, setIsEditing, formData, onInputChange, onCancel, onSave }) => {
  const positions = ["Head of design", "Senior Designer", "Lead Designer"];
  const departments = ["Design", "Engineering", "Product", "Marketing"];
  const statuses = ["Admin", "User", "Guest"];
  const navigate = useNavigate()
const handleClick = () => {
   navigate("/login")
}
  return (
    <div className="bg-white dark:bg-gray-900 rounded-3xl p-8 shadow-lg shadow-gray-500/20 dark:shadow-black/30 w-[360px]">
      <div className="mb-6">
        <h2 className="text-xl font-medium text-gray-900 dark:text-gray-100">
          {isEditing ? 'Edit profile' : 'My Profile'}
        </h2>
      </div>

      <div className="flex items-center mb-8">
        <div className="relative">
          <div className="w-16 h-16 rounded-full overflow-hidden">
            <img
              src={UserProfile}
              alt="Profile"
              className="w-full h-full object-cover"
            />
          </div>
          {isEditing && <span className="absolute -top-1 -right-1 text-xs bg-white dark:bg-gray-800 text-green-600 px-2 py-0.5 rounded-full">Active</span>}
        </div>
        <div className="ml-4">
          <span className="text-xs text-green-600">Active</span>
          <h3 className="text-base font-medium text-gray-900 dark:text-gray-100">Andrew Bojangles</h3>
        </div>
      </div>

      <div className="space-y-6">
        <div>
          <label className="text-sm text-gray-500 dark:text-gray-400 block mb-2">Position</label>
          <Dropdown
            value={formData.position}
            options={positions}
            onChange={(value) => onInputChange('position', value)}
            disabled={!isEditing}
          />
        </div>

        <div>
          <label className="text-sm text-gray-500 dark:text-gray-400 block mb-2">Department</label>
          <Dropdown
            value={formData.department}
            options={departments}
            onChange={(value) => onInputChange('department', value)}
            disabled={!isEditing}
          />
        </div>

        <div>
          <label className="text-sm text-gray-500 dark:text-gray-400 block mb-2">Status</label>
          <Dropdown
            value={formData.status}
            options={statuses}
            onChange={(value) => onInputChange('status', value)}
            disabled={!isEditing}
          />
        </div>
      </div>

      <div className="mt-8">
        {isEditing ? (
          <div className="flex justify-between">
            <button 
              onClick={onCancel}
              className="text-sm bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-gray-100 px-7 py-2 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600"
            >
              Cancel
            </button>
            <button 
              onClick={onSave}
              className="bg-green-700 text-white px-8 py-2 rounded-md text-sm hover:bg-green-800"
            >
              Save
            </button>
          </div>
        ) : (
          <div className='flex justify-between gap-2'>
            <button onClick={handleClick} className="w-full bg-red-600 text-white py-2.5 rounded-md text-sm hover:bg-red-700">
              Log Out
            </button>
            <button 
              onClick={() => setIsEditing(true)}
              className="w-full bg-green-700 text-white py-2.5 rounded-md text-sm hover:bg-green-800"
            >
              Edit
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const ProfileEditor = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: 'Andrew Bojangles',
    position: 'Head of design',
    department: 'Design',
    status: 'Admin'
  });
  const [tempFormData, setTempFormData] = useState(formData);

  const handleInputChange = (field, value) => {
    setTempFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSave = () => {
    setFormData(tempFormData);
    setIsEditing(false);
    setIsOpen(false);
  };

  const handleCancel = () => {
    setTempFormData(formData);
    setIsEditing(false);
    setIsOpen(false);
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="relative"
      >
        <div className="w-12 h-12 rounded-full overflow-hidden">
          <img
            src={UserProfile}
            alt="Profile"
            className="w-full h-full object-cover"
          />
        </div>
      </button>

      <Modal isOpen={isOpen} onClose={() => {
        setIsOpen(false);
        setIsEditing(false);
      }}>
        <div className="flex">
          <ProfileSection
            isEditing={isEditing}
            formData={tempFormData}
            onInputChange={handleInputChange}
            onCancel={handleCancel}
            onSave={handleSave}
            setIsEditing={setIsEditing}
          />
        </div>
      </Modal>
    </>
  );
};

export default ProfileEditor;