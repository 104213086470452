import React from "react";
import ImageUploader from "../ImageUploder";

const CampaignModel = ({
  isAddDialogOpen,
  setIsAddDialogOpen,
  newCampaign = {},
  setNewCampaign = () => {}, // Ensure it's a function to avoid errors
  handleAddCampaign,
}) => {
  if (!isAddDialogOpen) return null;

  const handleInputChange = (field, value) => {
    setNewCampaign((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg max-w-2xl w-full shadow-md shadow-green-900 dark:shadow-none max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <h2 className="text-2xl text-green-800 dark:text-green-400 font-bold mb-6">
            Add A Campaign
          </h2>

          <div className="space-y-4 mb-6">
            <input
              type="text"
              placeholder="Enter Campaign Name"
              className="w-full p-3 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-2 focus:ring-green-500 dark:focus:ring-green-400 transition duration-300"
              value={newCampaign.name || ""}
              onChange={(e) => handleInputChange("name", e.target.value)}
            />
            <input
              type="text"
              placeholder="Enter Brand Name"
              className="w-full p-3 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-2 focus:ring-green-500 dark:focus:ring-green-400 transition duration-300"
              value={newCampaign.brand || ""}
              onChange={(e) => handleInputChange("brand", e.target.value)}
            />
            <textarea
              placeholder="Enter Campaign Description Here..."
              className="w-full p-3 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-2 focus:ring-green-500 dark:focus:ring-green-400 transition duration-300 min-h-[120px]"
              value={newCampaign.description || ""}
              onChange={(e) => handleInputChange("description", e.target.value)}
            />
          </div>

          <div className="flex gap-8 items-start my-7">
            <ImageUploader
              onUpload={(file) => handleInputChange("image", file)}
            />
            <div className="flex flex-col gap-4 flex-1">
              <input
                type="number"
                placeholder="Enter Ademption Rate (%)"
                className="w-full p-3 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-2 focus:ring-green-500 dark:focus:ring-green-400 transition duration-300"
                value={newCampaign.ademptionRate || ""}
                onChange={(e) => handleInputChange("ademptionRate", e.target.value)}
              />
              <input
                type="number"
                placeholder="Enter Win Percentage (%)"
                className="w-full p-3 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-2 focus:ring-green-500 dark:focus:ring-green-400 transition duration-300"
                value={newCampaign.winPercentage || ""}
                onChange={(e) => handleInputChange("winPercentage", e.target.value)}
              />
              <input
                type="text"
                placeholder="Enter Coupon"
                className="w-full p-3 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-2 focus:ring-green-500 dark:focus:ring-green-400 transition duration-300"
                value={newCampaign.coupon || ""}
                onChange={(e) => handleInputChange("coupon", e.target.value)}
              />
            </div>
          </div>

          <div className="flex justify-end gap-4 mt-6">
            <button
              onClick={() => setIsAddDialogOpen(false)}
              className="px-6 py-2 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 transition duration-300"
            >
              Cancel
            </button>
            <button
              onClick={handleAddCampaign}
              className="px-8 py-2 bg-green-800 dark:bg-green-600 text-white rounded-lg hover:bg-green-700 dark:hover:bg-green-500 transition duration-300"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignModel;
