import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import { FaRegCircleUser } from "react-icons/fa6";
import { SiBrandfolder, SiCampaignmonitor } from "react-icons/si";
import { ImPriceTags } from "react-icons/im";
import { IoSettings } from "react-icons/io5";
import { HiX, HiOutlineMenuAlt2 } from "react-icons/hi";
import { Moon, Sun } from "lucide-react";
import LogoImage from "../assets/images/Kiwi-Login.png";
import { FiHelpCircle } from "react-icons/fi";
import { useTheme } from "./ThemeContext"; // Adjust the import path if necessary

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(
    localStorage.getItem("sidebarOpen") !== null
      ? JSON.parse(localStorage.getItem("sidebarOpen"))
      : true
  );
  const { theme, toggleTheme } = useTheme(); // Use the ThemeContext here
  const location = useLocation();

  const menuItems = [
    {
      path: "/dashboard",
      icon: RxDashboard,
      label: "Dashboard",
      order: 1,
      state: { flag: true },
    },
    {
      path: "/usermanagement",
      icon: FaRegCircleUser,
      label: "User Management",
      order: 2,
    },
    {
      path: "/campaignmanagement",
      icon: SiCampaignmonitor,
      label: "Campaign Management",
      order: 3,
    },
    {
      path: "/brandmanagement",
      icon: SiBrandfolder,
      label: "Brand Management",
      order: 4,
    },
    {
      path: "/pricemanagement",
      icon: ImPriceTags,
      label: "Prize Management",
      order: 5,
    },
    { path: "/setting", icon: IoSettings, label: "Settings", order: 6 },
    {
      path: "/helpandsupport",
      icon: FiHelpCircle,
      label: "Help & Support",
      order: 7,
    },
  ].sort((a, b) => a.order - b.order);

  useEffect(() => {
    localStorage.setItem("sidebarOpen", JSON.stringify(isOpen));
  }, [isOpen]);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <aside
      className={`bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100 h-screen  ${
        isOpen ? "w-fit" : "w-18"
      } flex flex-col transition-all duration-300 relative`}
      aria-label="Sidebar Navigation"
    >
      {/* Toggle Sidebar Button */}
      <button
        onClick={toggleSidebar}
        aria-label={isOpen ? "Close sidebar" : "Open sidebar"}
        className="absolute top-4 right-6 text-gray-900 dark:text-gray-100 p-2 rounded-full shadow-md hover:bg-gray-200 dark:hover:bg-gray-700 z-[1000]"
      >
        {isOpen ? <HiX size={24} /> : <HiOutlineMenuAlt2 size={24} />}
      </button>

      {/* Logo Section */}
      <div className="flex items-center justify-center mt-8 mb-6">
        <img
          src={LogoImage}
          alt="Kiwi Logo"
          className={`h-20 w-20 ${isOpen ? "" : "hidden"}`}
        />
        {isOpen && (
          <span className="ml-4 text-xl font-bold" aria-hidden={!isOpen}>
            Kiwi
          </span>
        )}
      </div>

      {/* Theme Toggle
      <div className="flex justify-center mb-6">
        <button
          onClick={toggleTheme}
          aria-label={`Switch to ${theme === "light" ? "dark" : "light"} mode`}
          className="flex items-center gap-2 px-3 py-2 bg-gray-200 dark:bg-gray-700 rounded-lg shadow-md hover:bg-gray-300 dark:hover:bg-gray-600"
        >
          {theme === "light" ? <Moon className="w-5 h-5" /> : <Sun className="w-5 h-5" />}
          {isOpen && <span>{theme === "light" ? "Dark Mode" : "Light Mode"}</span>}
        </button>
      </div> */}

      {/* Navigation Menu */}
      <nav
        className="flex flex-col gap-4 my-4 rounded-full text-nowrap"
        aria-label="Main Navigation"
      >
        {menuItems.map(({ path, icon: Icon, label }) => (
          <div key={path} className="flex items-center">
            <Link
              to={path}
              state={path === "/dashboard" ? { flag: true } : undefined}
              aria-current={location.pathname === path ? "page" : undefined}
              className={`flex items-center gap-4 w-full rounded-full ${
                location.pathname === path
                  ? "bg-[#395b06] text-white mx-2 " +
                    (isOpen ? " mx-4 rounded-full" : " justify-center")
                  : "text-gray-700 dark:text-gray-300 hover:bg-gray-200 mx-2 dark:hover:bg-gray-700"
              } px-6 py-3 rounded-md transition-all duration-200`}
            >
              <Icon
                className={`text-2xl ${
                  location.pathname === path && !isOpen
                    ? "text-white"
                    : location.pathname === path
                    ? ""
                    : ""
                }`}
                aria-hidden="true"
              />
              {isOpen && (
                <span className="font-medium" aria-hidden={!isOpen}>
                  {label}
                </span>
              )}
            </Link>
          </div>
        ))}
      </nav>
    </aside>
  );
};

export default Sidebar;
