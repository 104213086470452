// firebase.js
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAW8miE1MPrQqUC2X9_4-CiXVSvDle1Zdw",
  authDomain: "kiwi-app-b91ed.firebaseapp.com",
  projectId: "kiwi-app-b91ed",
  storageBucket: "kiwi-app-b91ed.firebasestorage.app",
  messagingSenderId: "949116203360",
  appId: "1:949116203360:web:2ef712c24ef45d59eb6e05",
  measurementId: "G-W58BJG7PSY",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging };
