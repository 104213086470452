import React, { useState } from 'react';
import { Upload, Plus, X } from 'lucide-react';
import { Button,Input,Card,Select,Tabs,FilePreview,CampaignList } from './ui/UiComponents';

// Custom Metric Card Component
const MetricCard = ({ title, value, icon }) => (
  <Card className="flex flex-col bg-white dark:bg-gray-800">
    <div className="flex items-center justify-between pb-2">
      <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">{title}</h3>
      <span className="text-gray-400 dark:text-gray-500">{icon}</span>
    </div>
    <p className="text-2xl font-bold text-gray-900 dark:text-gray-100">{value}</p>
  </Card>
);

// Main Dashboard Component
const AdvertiserDashboard = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const [showForm, setShowForm] = useState(false);
  const [editingCampaign, setEditingCampaign] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [campaigns, setCampaigns] = useState([
    {
      id: 1,
      brandName: 'Example Brand',
      adType: 'image',
      startDate: '2024-11-25',
      budget: '5000',
      targetAudience: '25-34',
      region: 'north',
      status: 'active'
    }
  ]);
  
  const [campaign, setCampaign] = useState({
    brandName: '',
    adType: '',
    startDate: '',
    budget: '',
    targetAudience: '',
    region: '',
    coupon: "",
    winPercentage:"",
    redemption:""
  });

  const tabs = [
    { id: 'overview', label: 'Overview' },
    { id: 'campaigns', label: 'Campaigns' },
    { id: 'creatives', label: 'Creatives' },
    { id: 'analytics', label: 'Analytics' }
  ];

  const metrics = [
    { title: 'Total Impressions', value: '45,232', icon: '👁' },
    { title: 'Engagements', value: '12,845', icon: '🎯' },
    { title: 'Conversions', value: '2,156', icon: '✨' },
    { title: 'Click Rate', value: '4.8%', icon: '📊' }
  ];

  const adTypeOptions = [
    { value: 'image', label: 'Image Ad' },
    { value: 'video', label: 'Video Ad' },
    { value: 'interactive', label: 'Interactive Ad' }
  ];

  const audienceOptions = [
    { value: '18-24', label: '18-24 years' },
    { value: '25-34', label: '25-34 years' },
    { value: '35-44', label: '35-44 years' },
    { value: '45+', label: '45+ years' }
  ];

  const regionOptions = [
    { value: 'north', label: 'North' },
    { value: 'south', label: 'South' },
    { value: 'east', label: 'East' },
    { value: 'west', label: 'West' }
  ];

  const handleInputChange = (field, value) => {
    setCampaign(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleCreateCampaign = () => {
    const newCampaign = {
      id: Date.now(),
      ...campaign,
      status: 'active'
    };
    
    setCampaigns(prev => [...prev, newCampaign]);
    setCampaign({
      brandName: '',
      adType: '',
      startDate: '',
      budget: '',
      targetAudience: '',
      region: ''
    });
    setShowForm(false);
  };

  const handleEditCampaign = (campaign) => {
    setEditingCampaign(campaign);
    setCampaign(campaign);
    setShowForm(true);
    setActiveTab('campaigns');
  };

  const handleDeleteCampaign = (campaignId) => {
    setCampaigns(prev => prev.filter(c => c.id !== campaignId));
  };

  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    setUploadedFiles(prev => [...prev, ...files]);
  };

  const handleRemoveFile = (fileToRemove) => {
    setUploadedFiles(prev => prev.filter(file => file !== fileToRemove));
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'overview':
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {metrics.map((metric) => (
              <MetricCard
                key={metric.title}
                title={metric.title}
                value={metric.value}
                icon={metric.icon}
              />
            ))}
          </div>
        );

      case 'campaigns':
        return (
          <div className="space-y-4">
            {!showForm && (
              <div className="flex justify-end">
                <Button 
                  className='!min-w-fit !flex dark:bg-green-600 dark:hover:bg-green-500 text-white' 
                  onClick={() => setShowForm(true)}
                >
                  <div className='flex  items-center'>
                    <Plus className="w-4 h-4 mr-2" />
                    Create New Campaign
                  </div>
                </Button>
              </div>
            )}
            
            {showForm ? (
              <Card
                title={editingCampaign ? "Edit Campaign" : "Create New Campaign"}
                description="Set up your advertising campaign details"
                className="dark:bg-gray-800 dark:border-gray-700 text-white"
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-10 ">
                  <Input
                    label="Brand Name"
                    value={campaign.brandName}
                    onChange={(e) => handleInputChange('brandName', e.target.value)}
                    placeholder="Enter your brand name"
                    className="dark:bg-gray-700 p-3 outline-green-600 w-full  dark:text-gray-100 rounded-lg"
                  />
                  <Select
                    label="Ad Type"
                    value={campaign.adType}
                    onChange={(e) => handleInputChange('adType', e.target.value)}
                    options={adTypeOptions}
                    className="dark:bg-gray-700  p-3 outline-green-600 w-full  dark:text-gray-100 rounded-lg"
                  />
                  {/* Additional inputs with dark mode styling */}
                  <Input
                    label="Redemption Rate"
                    value={campaign.redemption}
                    onChange={(e) => handleInputChange('redemption', e.target.value)}
                    placeholder="Enter Prize Redemption Rate"
                    className="dark:bg-gray-700 p-3 outline-green-600 w-full   rounded-lg dark:text-gray-100"
                  />
                  <Input
                    label="Win Percentage"
                    value={campaign.winPercentage}
                    onChange={(e) => handleInputChange('winPercentage', e.target.value)}
                    placeholder="Enter Win Percentage"
                    className="dark:bg-gray-700 p-3 outline-green-600 w-full  dark:text-gray-100 rounded-lg "
                  />
                  {/* Rest of the inputs... */}
                </div>
                <div className="flex justify-end space-x-2 mt-4">
                  <Button 
                    variant="outline" 
                    className="dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-700"
                    onClick={() => {
                      setShowForm(false);
                      setEditingCampaign(null);
                      setCampaign({
                        brandName: '',
                        adType: '',
                        startDate: '',
                        budget: '',
                        targetAudience: '',
                        region: ''
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <Button 
                    className="dark:bg-green-600 dark:hover:bg-green-500"
                    onClick={handleCreateCampaign}
                  >
                    {editingCampaign ? 'Update Campaign' : 'Create Campaign'}
                  </Button>
                </div>
              </Card>
            ) : (
              <CampaignList 
                campaigns={campaigns}
                onEdit={handleEditCampaign}
                onDelete={handleDeleteCampaign}
              />
            )}
          </div>
        );

      case 'creatives':
        return (
          <Card 
            title="Ad Creatives" 
            description="Upload and manage your ad content"
            className="dark:bg-gray-800 dark:border-gray-700"
          >
            <div className="space-y-4">
              <div className="border-2 border-dashed rounded-lg p-8 text-center dark:border-gray-600">
                <div className="text-4xl mb-4">
                  <Upload className="mx-auto h-12 w-12 text-gray-400 dark:text-gray-500" />
                </div>
                <input
                  type="file"
                  id="file-upload"
                  className="hidden"
                  multiple
                  onChange={handleFileUpload}
                  accept="image/, video/"
                />
                <label htmlFor="file-upload">
                  <Button 
                    as="span" 
                    className="dark:bg-green-600 dark:hover:bg-green-500"
                  >
                    Upload Files
                  </Button>
                </label>
                <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                  Support for JPG, PNG, GIF up to 5MB or MP4, MOV up to 20MB
                </p>
              </div>
            </div>
          </Card>
        );

      case 'analytics':
        return (
          <Card 
            title="Campaign Performance" 
            description="View detailed analytics and insights"
            className="dark:bg-gray-800 dark:border-gray-700"
          >
            <div className="h-96 flex items-center justify-center border rounded dark:border-gray-700">
              <p className="text-gray-500 dark:text-gray-400">Campaign performance charts will be displayed here</p>
            </div>
          </Card>
        );

      default:
        return null;
    }
  };

  return (
    <div className="w-full  mx-auto p-4 space-y-4 bg-gray-200 dark:bg-gray-900 min-h-screen">
      <Card className="dark:bg-gray-800 dark:border-gray-700">
        <div className="flex items-center justify-between">
          <div className=''>
            <h1 className="text-xl font-bold text-gray-900 dark:text-gray-100">Advertiser Dashboard</h1>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Manage your ad campaigns and view performance metrics
            </p>
          </div>
          {activeTab !== 'campaigns' && (
            <Button 
              onClick={() => {
                setActiveTab('campaigns');
                setShowForm(true);
              }}
              className="dark:bg-green-600 dark:hover:bg-green-500"
            >
              <Plus className="w-4 h-4 mr-2" />
              Create New Campaign
            </Button>
          )}
        </div>
      </Card>

      <div className="space-y-4">
        <Tabs
          tabs={tabs}
          activeTab={activeTab}
          onTabChange={(tabId) => {
            setActiveTab(tabId);
            if (tabId !== 'campaigns') {
              setShowForm(false);
              setEditingCampaign(null);
            }
          }}
          className="dark:bg-gray-800"
        />
        {renderTabContent()}
      </div>
    </div>
  );
};

export default AdvertiserDashboard;