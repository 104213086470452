import React, { createContext, useContext, useState, useEffect } from "react";

// Create Theme Context
const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  // Initialize theme state
  const [theme, setTheme] = useState(() => {
    // Get saved theme from localStorage or system preference
    const savedTheme = localStorage.getItem("theme");
    const systemPreference =
      typeof window !== "undefined" && window.matchMedia("(prefers-color-scheme: dark)").matches;
    return savedTheme || (systemPreference ? "dark" : "light");
  });

  useEffect(() => {
    // Apply the theme to the document root
    document.documentElement.classList.toggle("dark", theme === "dark");
    localStorage.setItem("theme", theme);

    
  }, [theme]);

  // Toggle theme function
  const toggleTheme = () => {
    setTheme((prev) => (prev === "light" ? "dark" : "light"));
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

// Custom hook to use ThemeContext
export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
