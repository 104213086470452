import React, { useState } from "react";
import KiwiLogo from "../assets/images/Kiwi-Login.png";
import LoginPageImage from "../assets/images/Login-Page-Image.png";
import { FaArrowRightLong, FaEye, FaEyeSlash } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState({});
  const navigate = useNavigate();
  const [auth, setAuth] = useState({
    email: "",
    password: "",
  });

  const validateInput = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Validate email
    if (!auth.email) {
      newErrors.email = "Email is required.";
    } else if (!emailRegex.test(auth.email)) {
      newErrors.email = "Invalid email format.";
    }

    // Validate password
    if (!auth.password) {
      newErrors.password = "Password is required.";
    } else if (auth.password.length < 8) {
      newErrors.password = "Password must be at least 8 characters long.";
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate input fields
    const validationErrors = validateInput();
    if (Object.keys(validationErrors).length > 0) {
      setError(validationErrors);
      return;
    }

    try {
      const resJson = {};
      if (auth.email !== "admin123@gmail.com") {
        resJson.error = "admin not registered";
        toast.error("admin not registered");
      } else if (auth.password !== "admin@123") {
        toast.error("password is not correct");
      } else {
        localStorage.setItem("user", JSON.stringify(resJson));
        localStorage.setItem('login','true');
        navigate("/dashboard", {state:{flag:true}});

        toast.success("Login Successfully:", resJson);
      }
    } catch (error) {
      console.error("Error occurred during login:", error.message);
    }
  };

  return (
    <div className="max-h-screen flex items-center justify-center p-4">
      <div className="bg-white w-full  min-h-screen rounded-lg overflow-hidden grid grid-cols-1 lg:grid-cols-2 shadow-lg">
        {/* Left Section */}
        <div className="px-6 sm:px-10 py-8 flex flex-col justify-center">
          <div className="flex flex-col items-center lg:items-start mb-6">
            <img
              className="h-[100px] w-auto mb-4 lg:h-[150px]"
              src={KiwiLogo}
              alt="Kiwi Logo"
            />
            <p className="text-center lg:text-left text-gray-600 mb-2">
              Welcome Back !!!
            </p>
            <h1 className="font-bold text-2xl lg:text-3xl text-center lg:text-left mb-6">
              Login
            </h1>
          </div>

          <form
            onSubmit={handleSubmit}
            className="space-y-4 w-full max-w-md mx-auto lg:mx-0"
          >
            <div>
              <label className="block mb-2 text-sm font-medium">Email</label>
              <input
                onChange={(e) =>
                  setAuth((prevAuth) => ({
                    ...prevAuth,
                    email: e.target.value,
                  }))
                }
                className="bg-[#C0DBEA] w-full h-[46px] px-3 rounded-md border border-transparent focus:outline-none focus:ring-2 focus:ring-[#3E782A]"
                type="text"
                placeholder="admin@gmail.com"
              />
              {error.email && (
                <p className="text-red-500 text-sm mt-1 h-5">
                  {error.email}
                </p>
              )}
            </div>

            <div className="relative">
              <label className="block mb-2 text-sm font-medium">Password</label>
              <div className="relative">
                <input
                  onChange={(e) =>
                    setAuth((prevAuth) => ({
                      ...prevAuth,
                      password: e.target.value,
                    }))
                  }
                  className="bg-[#C0DBEA] w-full h-[46px] px-3 pr-10 rounded-md border border-transparent focus:outline-none focus:ring-2 focus:ring-[#3E782A]"
                  type={showPassword ? "text" : "password"}
                  placeholder="..........."
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-3 top-1/2 -translate-y-1/2 text-green-800 flex items-center hover:text-green-600"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
              {error.password && (
                <p className="text-red-500 text-sm mt-1 h-5">
                  {error.password}
                </p>
              )}
            </div>

            <div className="text-right">
              <a href="#" className="text-sm text-[#3E782A] hover:underline">
                Forgot Password
              </a>
            </div>

            <button
              type="submit"
              className="bg-[#3E782A] h-[46px] w-full lg:w-[150px] rounded-full text-white flex items-center justify-center gap-3 hover:bg-opacity-90 transition-colors duration-300 mx-auto lg:mx-0"
            >
              Login <FaArrowRightLong />
            </button>
          </form>
        </div>

        {/* Right Section */}
        <div className="bg-[#2F5B20] hidden lg:flex items-center justify-center">
          <img
            className="h-[80%] w-[80%] object-contain relative right-[340px]"
            src={LoginPageImage}
            alt="Login Page Illustration"
          />
        </div>
      </div>
    </div>
  );
};

export default Login;