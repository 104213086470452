import React from "react";
import { createBrowserRouter, RouterProvider, useLocation, Outlet, Navigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import UserManagement from "./pages/UserManagement";
import Sidebar from "./components/Sidebar";
import CampaignManagement from "./pages/CampaignManagement";
import BrandManagement from "./pages/BrandManagement";
import PriceManagement from "./pages/PriceManagement";
import Setting from "./pages/Setting";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Header from "./components/Header";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from "./components/ThemeContext";
import HelpAndSupport from "./pages/HelpAndSupport";
import SplashScreen from "./components/SplashScreen";
import AnimatedErrorPage from "./pages/ErrorPage";

// Separate layout for error page
const ErrorLayout = () => {
  return (
    <ThemeProvider>
      <div className="flex-grow w-full h-screen">
        <Outlet />
      </div>
    </ThemeProvider>
  );
};

const AppLayout = () => {
  const location = useLocation();
  
  // Paths that don't show sidebar and header
  const noSidebarHeaderPaths = ['/', '/login', '/signup'];

  return (
    <ThemeProvider>
      <div className="flex flex-col">
        <div className="flex max-h-screen min-h-screen overflow-hidden">
          {!noSidebarHeaderPaths.includes(location.pathname) && <Sidebar />}
          <div
            className={`flex-grow overflow-auto ${
              noSidebarHeaderPaths.includes(location.pathname) ? "w-full" : ""
            }`}
          >
            {!noSidebarHeaderPaths.includes(location.pathname) && <Header />}
            <Outlet />
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

const AuthLayout = () => {
  return (
    <ThemeProvider>
      <div className="flex-grow">
        <Outlet />
      </div>
    </ThemeProvider>
  );
};

function App() {
  const appRouter = createBrowserRouter([
    {
      path: "/",
      element: <AppLayout />,
      children: [
        { path: "/", element: <SplashScreen /> },
        { path: "/dashboard", element: <Dashboard /> },
        { path: "/usermanagement", element: <UserManagement /> },
        { path: "/campaignmanagement", element: <CampaignManagement /> },
        { path: "/brandmanagement", element: <BrandManagement /> },
        { path: "/pricemanagement", element: <PriceManagement /> },
        { path: "/setting", element: <Setting /> },
        { path: "/helpandsupport", element: <HelpAndSupport /> },
      ],
    },
    {
      path: "/",
      element: <AuthLayout />,
      children: [
        { path: "/login", element: <Login /> },
        { path: "/signup", element: <SignUp /> },
      ],
    },
    // Separate route for error page
    {
      path: "*",
      element: <ErrorLayout />,
      children: [
        { path: "*", element: <AnimatedErrorPage /> }
      ]
    }
  ]);

  return (
    <div className="App">
      <RouterProvider router={appRouter} />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;