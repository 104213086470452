import React, { useEffect, useState } from "react";
import { messaging } from "./firebase";
import { getToken, onMessage } from "firebase/messaging";

const AppFirebase = () => {
  const [token, setToken] = useState("");
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    // Request notification permission
    const requestPermission = async () => {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        try {
          const currentToken = await getToken(messaging, {
            vapidKey: "BErwIdRxo26cptcQ56TSO9oNE9tvU31jFCljfRdRy1JUzw53woXPc5L_MyIjvx8gscdiPL_2fb9CNPrPB9jbcDo", // Replace with your VAPID key
          });
          if (currentToken) {
            setToken(currentToken);
            console.log("FCM Token:", currentToken);
          } else {
            console.log("No FCM token retrieved.");
          }
        } catch (err) {
          console.error("Error retrieving token:", err);
        }
      } else {
        console.log("Notification permission denied.");
      }
    };

    requestPermission();
  }, []);

  useEffect(() => {
    // Handle foreground notifications
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log("Foreground notification:", payload);
      setNotification(payload.notification);
      alert(`New notification: ${payload.notification.title}`);
    });

    return () => unsubscribe();
  }, []);

  return (
    <div>
      {/* <h1>React Firebase Push Notifications</h1> */}
      {/* {token ? <p>FCM Token: {token}</p> : <p>Requesting permission...</p>} */}
      {notification && (
        <div>
          <h2>Notification:</h2>
          <p>{notification.title}</p>
          <p>{notification.body}</p>
        </div>
      )}
    </div>
  );
};

export default AppFirebase;
