import React, { useState, useEffect } from "react";
import {
  Settings as SettingsIcon,
  User,
  Bell,
  Lock,
  Palette,
  CreditCard,
  Moon,
  Sun,
  Check,
  AlertCircle,
  Save,
} from "lucide-react";
import { useTheme } from "../components/ThemeContext";

const LANGUAGES = [
  "English", 
  "Spanish", 
  "French", 
  "German", 
  "Chinese", 
  "Japanese", 
  "Arabic"
];

const Settings = () => {
  const { theme, toggleTheme } = useTheme();
  const [darkMode, setDarkMode] = useState(theme === "dark");
  const [activeSection, setActiveSection] = useState("profile");
  const [notifications, setNotifications] = useState({
    email: true,
    push: false,
    sms: false,
  });
  const [profileSettings, setProfileSettings] = useState({
    username: "johndoe",
    email: "john.doe@example.com",
    language: "English",
  });
  const [securitySettings, setSecuritySettings] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    passwordStrength: 0,
  });
  const [formErrors, setFormErrors] = useState({
    username: "",
    email: "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [saveStatus, setSaveStatus] = useState({
    profile: { saved: true, message: "" },
    security: { saved: true, message: "" },
  });

  useEffect(() => {
    setDarkMode(theme === "dark");
  }, [theme]);

  const settingsSections = [
    {
      id: "profile",
      icon: <User className="w-5 h-5" />,
      title: "Profile",
    },
    {
      id: "notifications",
      icon: <Bell className="w-5 h-5" />,
      title: "Notifications",
    },
    {
      id: "security",
      icon: <Lock className="w-5 h-5" />,
      title: "Security",
    },
    {
      id: "appearance",
      icon: <Palette className="w-5 h-5" />,
      title: "Appearance",
    },
    {
      id: "billing",
      icon: <CreditCard className="w-5 h-5" />,
      title: "Billing",
    },
  ];

  const handleToggleDarkMode = () => {
    toggleTheme();
  };

  const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length > 7) strength++;
    if (password.match(/[a-z]+/)) strength++;
    if (password.match(/[A-Z]+/)) strength++;
    if (password.match(/[0-9]+/)) strength++;
    if (password.match(/[$@#&!]+/)) strength++;
    return strength;
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateProfileSettings = () => {
    const errors = { username: "", email: "" };
    let isValid = true;

    if (!profileSettings.username.trim()) {
      errors.username = "Username is required";
      isValid = false;
    }

    if (!profileSettings.email.trim()) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!validateEmail(profileSettings.email)) {
      errors.email = "Invalid email format";
      isValid = false;
    }

    setFormErrors((prev) => ({ ...prev, ...errors }));
    return isValid;
  };

  const validateSecuritySettings = () => {
    const errors = { 
      currentPassword: "", 
      newPassword: "", 
      confirmPassword: "" 
    };
    let isValid = true;

    if (!securitySettings.currentPassword) {
      errors.currentPassword = "Current password is required";
      isValid = false;
    }

    if (!securitySettings.newPassword) {
      errors.newPassword = "New password is required";
      isValid = false;
    } else if (securitySettings.newPassword.length < 8) {
      errors.newPassword = "Password must be at least 8 characters";
      isValid = false;
    }

    if (securitySettings.newPassword !== securitySettings.confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
      isValid = false;
    }

    setFormErrors((prev) => ({ ...prev, ...errors }));
    return isValid;
  };

  const handleProfileSave = () => {
    if (validateProfileSettings()) {
      setSaveStatus(prev => ({
        ...prev,
        profile: { 
          saved: true, 
          message: "Profile updated successfully!" 
        }
      }));
      setTimeout(() => {
        setSaveStatus(prev => ({
          ...prev,
          profile: { saved: true, message: "" }
        }));
      }, 3000);
    }
  };

  const handleSecuritySave = () => {
    if (validateSecuritySettings()) {
      setSaveStatus(prev => ({
        ...prev,
        security: { 
          saved: true, 
          message: "Password updated successfully!" 
        }
      }));
      setSecuritySettings({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        passwordStrength: 0,
      });
      setTimeout(() => {
        setSaveStatus(prev => ({
          ...prev,
          security: { saved: true, message: "" }
        }));
      }, 3000);
    }
  };

  const renderAppearanceSettings = () => (
    <div className="space-y-6 bg-white dark:bg-gray-900 p-6 rounded-xl shadow-lg">
      <h2 className="text-2xl font-bold text-gray-800 dark:text-white border-b pb-3">
        Appearance
      </h2>
      <div className="space-y-4">
        <div className="bg-gray-100 dark:bg-gray-800 p-4 rounded-lg flex items-center justify-between hover:bg-gray-200 dark:hover:bg-gray-700 transition-all">
          <div className="flex items-center space-x-4">
            {darkMode ? (
              <Moon className="text-purple-600 w-8 h-8" />
            ) : (
              <Sun className="text-yellow-500 w-8 h-8" />
            )}
            <div>
              <h3 className="font-semibold text-gray-800 dark:text-white text-lg">
                {darkMode ? "Dark Mode" : "Light Mode"}
              </h3>
              <p className="text-sm text-gray-600 dark:text-gray-400">
                Customize your visual experience
              </p>
            </div>
          </div>
          <button
            onClick={handleToggleDarkMode}
            className={`w-16 h-8 bg-gray-300 rounded-full relative flex items-center transition-colors duration-300 ${
              darkMode ? "bg-purple-600" : "bg-gray-300"
            }`}
          >
            <span
              className={`w-6 h-6 bg-white rounded-full shadow-md transform transition-transform duration-300 ${
                darkMode ? "translate-x-8" : "translate-x-0"
              }`}
              style={{
                position: "absolute",
                left: 10,
                transform: darkMode ? "translateX(100%)" : "translateX(0)",
              }}
            />
          </button>
        </div>
      </div>
    </div>
  );

  const renderProfileSettings = () => (
    <div className="bg-white dark:bg-gray-900 p-6 rounded-xl shadow-lg space-y-6">
      <h2 className="text-2xl font-bold text-gray-800 dark:text-white border-b pb-3">
        Profile Settings
      </h2>
      {saveStatus.profile.message && (
        <div className={`p-4 rounded-lg ${
          saveStatus.profile.saved 
            ? "bg-green-100 text-green-800" 
            : "bg-red-100 text-red-800"
        }`}>
          {saveStatus.profile.message}
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Username
          </label>
          <input
            type="text"
            value={profileSettings.username}
            onChange={(e) => setProfileSettings(prev => ({
              ...prev, 
              username: e.target.value
            }))}
            className={`w-full px-3 py-2 border rounded-lg 
              bg-white dark:bg-gray-800 text-gray-900 dark:text-white
              focus:ring-2 focus:ring-blue-500 transition-all
              ${formErrors.username 
                ? "border-red-500 focus:ring-red-500" 
                : "border-gray-300 dark:border-gray-700"
              }`}
          />
          {formErrors.username && (
            <p className="text-red-500 text-xs mt-1">
              {formErrors.username}
            </p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Email
          </label>
          <input
            type="email"
            value={profileSettings.email}
            onChange={(e) => setProfileSettings(prev => ({
              ...prev, 
              email: e.target.value
            }))}
            className={`w-full px-3 py-2 border rounded-lg 
              bg-white dark:bg-gray-800 text-gray-900 dark:text-white
              focus:ring-2 focus:ring-blue-500 transition-all
              ${formErrors.email 
                ? "border-red-500 focus:ring-red-500" 
                : "border-gray-300 dark:border-gray-700"
              }`}
          />
          {formErrors.email && (
            <p className="text-red-500 text-xs mt-1">
              {formErrors.email}
            </p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Language
          </label>
          <select
            value={profileSettings.language}
            onChange={(e) => setProfileSettings(prev => ({
              ...prev, 
              language: e.target.value
            }))}
            className="w-full px-3 py-2 border border-gray-300 dark:border-gray-700 rounded-lg 
              bg-white dark:bg-gray-800 text-gray-900 dark:text-white
              focus:ring-2 focus:ring-blue-500 transition-all"
          >
            {LANGUAGES.map(lang => (
              <option key={lang} value={lang}>{lang}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="flex justify-end">
        <button 
          onClick={handleProfileSave}
          className="flex items-center space-x-2 bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition-colors"
        >
          <Save className="w-5 h-5" />
          <span>Save Profile</span>
        </button>
      </div>
    </div>
  );

  // Existing renderNotificationsSettings and renderSecuritySettings methods remain the same as in previous implementation

  const renderNotificationsSettings = () => (
    <div className="bg-white dark:bg-gray-900 p-6 rounded-xl shadow-lg space-y-6">
      <h2 className="text-2xl font-bold text-gray-800 dark:text-white border-b pb-3">
        Notifications
      </h2>
      <div className="space-y-4">
        {Object.keys(notifications).map((type) => (
          <div
            key={type}
            className="flex items-center justify-between bg-gray-100 dark:bg-gray-800 p-4 rounded-lg"
          >
            <div>
              <h3 className="font-semibold capitalize">{type} Notifications</h3>
              <p className="text-sm text-gray-600 dark:text-gray-400">
                Receive {type} notifications
              </p>
            </div>
            <label className="flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={notifications[type]}
                onChange={() =>
                  setNotifications((prev) => ({
                    ...prev,
                    [type]: !prev[type],
                  }))
                }
                className="hidden"
              />
              <span
                className={`block w-12 h-6 rounded-full transition-colors ${
                  notifications[type]
                    ? "bg-green-500"
                    : "bg-gray-300 dark:bg-gray-600"
                }`}
              >
                <span
                  className={`block w-6 h-6 bg-white rounded-full shadow-md transform transition-transform ${
                    notifications[type] ? "translate-x-6" : "translate-x-0"
                  }`}
                />
              </span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );

  const renderSecuritySettings = () => (
    <div className="bg-white dark:bg-gray-900 p-6 rounded-xl shadow-lg space-y-6">
      <h2 className="text-2xl font-bold text-gray-800 dark:text-white border-b pb-3">
        Security
      </h2>
      <div className="grid grid-cols-1 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Current Password
          </label>
          <input
            type="password"
            className="w-full px-3 py-2 border border-gray-300 dark:border-gray-700 rounded-lg 
              bg-white dark:bg-gray-800 text-gray-900 dark:text-white
              focus:ring-2 focus:ring-blue-500 transition-all"
            value={securitySettings.currentPassword}
            onChange={(e) =>
              setSecuritySettings((prev) => ({
                ...prev,
                currentPassword: e.target.value,
                passwordStrength: calculatePasswordStrength(e.target.value),
              }))
            }
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            New Password
          </label>
          <input
            type="password"
            className="w-full px-3 py-2 border border-gray-300 dark:border-gray-700 rounded-lg 
              bg-white dark:bg-gray-800 text-gray-900 dark:text-white
              focus:ring-2 focus:ring-blue-500 transition-all"
            value={securitySettings.newPassword}
            onChange={(e) =>
              setSecuritySettings((prev) => ({
                ...prev,
                newPassword: e.target.value,
                passwordStrength: calculatePasswordStrength(e.target.value),
              }))
            }
          />
          <div className="mt-2 flex items-center space-x-2">
            {[1, 2, 3, 4, 5].map((level) => (
              <div
                key={level}
                className={`h-2 w-full rounded-full ${
                  securitySettings.passwordStrength >= level
                    ? "bg-green-500"
                    : "bg-gray-300 dark:bg-gray-700"
                }`}
              />
            ))}
          </div>
          <p className="text-sm mt-1 text-gray-600 dark:text-gray-400">
            {securitySettings.passwordStrength < 3
              ? "Weak password"
              : securitySettings.passwordStrength < 5
              ? "Medium strength"
              : "Strong password"}
          </p>
        </div>
        <button className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition-colors">
          Update Password
        </button>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-200 dark:bg-gray-800 p-8">
      <div className="max-w-4xl mx-auto bg-white dark:bg-gray-900 rounded-2xl shadow-2xl overflow-hidden">
        <div className="bg-gray-50 dark:bg-gray-700 p-4 flex items-center space-x-4 border-b dark:border-gray-600">
          <SettingsIcon className="w-8 h-8 text-gray-700 dark:text-white" />
          <h1 className="text-3xl font-bold text-gray-800 dark:text-white">
            Account Settings
          </h1>
        </div>
        <div className="p-6">
          <div className="flex space-x-4 mb-6 overflow-x-auto">
            {settingsSections.map((section) => (
              <button
                key={section.id}
                onClick={() => setActiveSection(section.id)}
                className={`flex items-center space-x-2 px-4 py-2 rounded-lg text-sm font-medium transition-all
                  ${
                    activeSection === section.id
                      ? "bg-blue-600 text-white hover:bg-blue-700"
                      : "bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-600"
                  }`}
              >
                {section.icon}
                <span>{section.title}</span>
              </button>
            ))}
          </div>
          <div>
            {activeSection === "profile" && renderProfileSettings()}
            {activeSection === "notifications" && renderNotificationsSettings()}
            {activeSection === "security" && renderSecuritySettings()}
            {activeSection === "appearance" && renderAppearanceSettings()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
