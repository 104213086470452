import React, { useState } from "react";
import { Pencil, Trash2 } from "lucide-react";
import { toast } from "react-toastify";

// Modal component for delete confirmation
const DeleteConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  campaignName,
}) => {
  return (
    <div
      className={`fixed inset-0 z-50 bg-gray-800 bg-opacity-50 flex justify-center items-center ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 w-96 shadow-md hover:shadow-gray-600">
        <h3 className="text-lg text-gray-900 dark:text-gray-100">
          Are you sure you want to delete this campaign?
        </h3>

        {/* <p className="text-gray-700 dark:text-gray-300">
          Campaign: {campaignName}
        </p> */}
        <div className="mt-4 flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="bg-gray-200 text-gray-700 dark:bg-gray-600 dark:text-gray-300 p-2 rounded"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="bg-red-500 text-white p-2 rounded"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

const CampaignTable = ({
  campaigns,
  onEditCampaign,
  onDeleteCampaign,
  setIsEditDialogOpen,
  setCurrentCampaign,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [campaignToDelete, setCampaignToDelete] = useState(null);

  const handleDeleteClick = (campaign) => {
    setCampaignToDelete(campaign);
    setIsModalOpen(true);
  };

  const handleConfirmDelete = () => {
    if (campaignToDelete) {
      onDeleteCampaign(campaignToDelete.id);
      toast.success("Campaign deleted successfully!");
      setIsModalOpen(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow overflow-x-auto">
      <table className="min-w-full">
        <thead className="bg-gray-50 dark:bg-gray-700">
          <tr>
            <th className="w-12 py-3 px-4">
              <input
                type="checkbox"
                className="rounded dark:bg-gray-600 dark:border-gray-500"
              />
            </th>
            <th className="text-left py-3 px-4 text-gray-600 dark:text-gray-300">
              Campaign Name
            </th>
            <th className="text-left py-3 px-4 text-gray-600 dark:text-gray-300">
              Status
            </th>
            <th className="text-left py-3 px-4 text-gray-600 dark:text-gray-300">
              Brand Name
            </th>
            <th className="text-left py-3 px-4 text-gray-600 dark:text-gray-300">
              Campaign Image
            </th>
            <th className="text-left py-3 px-4 text-gray-600 dark:text-gray-300">
              Engagement Stats
            </th>
            <th className="text-left py-3 px-4 text-gray-600 dark:text-gray-300">
              Created On
            </th>
            <th className="text-left py-3 px-4 text-gray-600 dark:text-gray-300">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
          {campaigns?.map((campaign) => (
            <tr
              key={campaign.id}
              className="hover:bg-gray-50 dark:hover:bg-gray-700"
            >
              <td className="py-3 px-4">
                <input
                  type="checkbox"
                  className="rounded dark:bg-gray-600 dark:border-gray-500"
                />
              </td>
              <td className="py-3 px-4 text-gray-900 dark:text-gray-100">
                {campaign.name}
              </td>
              <td className="py-3 px-4">
                <span
                  className={`px-2 py-1 rounded-full text-sm ${
                    campaign.status === "Active"
                      ? "bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-400"
                      : "bg-gray-100 text-gray-800 dark:bg-gray-900/30 dark:text-gray-400"
                  }`}
                >
                  {campaign.status}
                </span>
              </td>
              <td className="py-3 px-4 text-gray-900 dark:text-gray-100">
                {campaign.brand}
              </td>
              <td className="py-3 px-4 text-gray-900 dark:text-gray-100">
                {campaign.image}
              </td>
              <td className="py-3 px-4 text-gray-900 dark:text-gray-100">
                {campaign.engagement}
              </td>
              <td className="py-3 px-4 text-gray-900 dark:text-gray-100">
                {campaign.createdOn}
              </td>
              <td className="py-3 px-4">
                <div className="flex items-center space-x-2">
                  {/* Edit Button */}
                  <button
                    onClick={() => {
                      onEditCampaign(campaign);
                      setIsEditDialogOpen(true);
                      setCurrentCampaign(campaign);
                    }}
                    className="text-blue-500 hover:bg-blue-50 dark:text-blue-400 dark:hover:bg-blue-900/20 p-1.5 rounded"
                    aria-label="Edit Campaign"
                  >
                    <Pencil className="w-5 h-5" />
                  </button>

                  {/* Delete Button */}
                  <button
                    onClick={() => handleDeleteClick(campaign)}
                    className="text-red-500 hover:bg-red-50 dark:text-red-400 dark:hover:bg-red-900/20 p-1.5 rounded"
                    aria-label="Delete Campaign"
                  >
                    <Trash2 className="w-5 h-5" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Delete Confirmation Modal */}
      <DeleteConfirmationModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirmDelete}
        campaignName={campaignToDelete?.name}
      />
    </div>
  );
};

export default CampaignTable;
