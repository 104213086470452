import React, { useState } from 'react';
import { Upload, Plus, X } from 'lucide-react';


// Custom Button Component
export const Button = ({ children, variant = 'primary', className = '', ...props }) => (
     <button
       className={`px-4 py-2 rounded-md min-w-fit font-medium transition-colors
       ${variant === 'primary' ? 'bg-blue-600 text-white hover:bg-blue-700' : 
         variant === 'outline' ? 'border border-gray-300 bg-white hover:bg-gray-50' : 
         'bg-gray-100 hover:bg-gray-200'} 
       ${className}`}
       {...props}
     >
       {children}
     </button>
   );
   
   // Custom Input Component
  export const Input = ({ label, error, ...props }) => (
     <div className="space-y-1">
       {label && (
         <label className="block text-sm font-medium text-gray-700">{label}</label>
       )}
       <input
         className={`w-full px-3 py-2 border rounded-md shadow-sm focus:ring-1 focus:ring-blue-500 focus:border-blue-500
         ${error ? 'border-red-300' : 'border-gray-300'}`}
         {...props}
       />
       {error && <p className="text-sm text-red-600">{error}</p>}
     </div>
   );
   
   // Custom Select Component
  export const Select = ({ label, options, error, ...props }) => (
     <div className="space-y-1">
       {label && (
         <label className="block text-sm font-medium text-gray-700">{label}</label>
       )}
       <select
         className={`w-full px-3 py-2 border rounded-md shadow-sm focus:ring-1 focus:ring-blue-500 focus:border-blue-500
         ${error ? 'border-red-300' : 'border-gray-300'}`}
         {...props}
       >
         <option value="">Select an option</option>
         {options.map(option => (
           <option key={option.value} value={option.value}>
             {option.label}
           </option>
         ))}
       </select>
       {error && <p className="text-sm text-red-600">{error}</p>}
     </div>
   );
   
   // Custom Card Component
 export  const Card = ({ title, description, children, className = '' }) => (
     <div className={`bg-white rounded-lg shadow ${className}`}>
       {(title || description) && (
         <div className="p-4 border-b">
           {title && <h3 className="text-lg font-medium">{title}</h3>}
           {description && <p className="mt-1 text-sm text-gray-500">{description}</p>}
         </div>
       )}
       <div className="p-4">{children}</div>
     </div>
   );

export const Tabs = ({ tabs, activeTab, onTabChange }) => (
     <div className="border-b border-gray-200">
       <nav className="flex -mb-px">
         {tabs.map((tab) => (
           <button
             key={tab.id}
             onClick={() => onTabChange(tab.id)}
             className={`py-4 px-6 text-sm font-medium border-b-2 transition-colors
               ${activeTab === tab.id
                 ? 'border-blue-500 text-blue-600'
                 : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
               }`}
           >
             {tab.label}
           </button>
         ))}
       </nav>
     </div>
   );

  export const FilePreview = ({ file, onRemove }) => (
     <div className="flex items-center justify-between p-2 bg-gray-50 rounded-md">
       <div className="flex items-center space-x-2">
         <div className="text-gray-500">📎</div>
         <span className="text-sm truncate max-w-xs">{file.name}</span>
       </div>
       <button
         onClick={() => onRemove(file)}
         className="text-gray-500 hover:text-red-500"
       >
         <X size={16} />
       </button>
     </div>
   );

   export const CampaignList = ({ campaigns, onEdit, onDelete }) => (
     <div className="space-y-4">
       {campaigns.map((campaign) => (
         <Card key={campaign.id} className="hover:shadow-md transition-shadow">
           <div className="flex justify-between items-start">
             <div>
               <h3 className="font-medium">{campaign.brandName}</h3>
               <p className="text-sm text-gray-500">
                 {campaign.adType} • Budget: ${campaign.budget}
               </p>
               <p className="text-sm text-gray-500">
                 Target: {campaign.targetAudience} • Region: {campaign.region}
               </p>
               <p className="text-sm text-gray-500">
                 Start Date: {new Date(campaign.startDate).toLocaleDateString()}
               </p>
             </div>
             <div className="space-x-2">
               <Button variant="outline" onClick={() => onEdit(campaign)}>
                 Edit
               </Button>
               <Button 
                 variant="outline" 
                 className="text-red-600 hover:bg-red-50"
                 onClick={() => onDelete(campaign.id)}
               >
                 Delete
               </Button>
             </div>
           </div>
         </Card>
       ))}
     </div>
   );