// CampaignDashboard.jsx
import React, { useState, useEffect } from "react";
import CampaignTable from "../components/model-&-table/CampaignTable";
import CampaignModel from "../components/model-&-table/CampaignModel";
import { Moon, Sun } from "lucide-react";

const CampaignDashboard = () => {
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ||
      (window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light")
  );
  const [campaigns, setCampaigns] = useState([
    {
      id: 1,
      name: "Andrew Bojangles",
      status: "Active",
      brand: "LG",
      image: "designer.jpg",
      engagement: 500,
      createdOn: "2 days ago",
    },
    {
      id: 2,
      name: "Spring Sale",
      status: "Inactive",
      brand: "Samsung",
      image: "sale.jpg",
      engagement: 300,
      createdOn: "5 days ago",
    },
    {
      id: 3,
      name: "Black Friday Bonanza",
      status: "Active",
      brand: "Sony",
      image: "friday.jpg",
      engagement: 1200,
      createdOn: "1 week ago",
    },
  ]);

  // Theme Application Effect
  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    localStorage.setItem("theme", theme);
  }, [theme]);

  // Theme Toggle Function
  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  const [newCampaign, setNewCampaign] = useState({});
  const [currentCampaign, setCurrentCampaign] = useState(null);
  const [filteredCampaigns, setFilteredCampaigns] = useState(campaigns);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [brandFilter, setBrandFilter] = useState("");

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    const filtered = campaigns.filter((campaign) =>
      campaign.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    applyFilters(filtered);
  };

  const handleStatusFilterChange = (e) => {
    const filter = e.target.value;
    setStatusFilter(filter);
    applyFilters(campaigns, filter, brandFilter);
  };

  const handleBrandFilterChange = (e) => {
    const filter = e.target.value;
    setBrandFilter(filter);
    applyFilters(campaigns, statusFilter, filter);
  };

  const applyFilters = (
    filtered = campaigns,
    status = statusFilter,
    brand = brandFilter
  ) => {
    let result = filtered;
    if (status)
      result = result.filter((campaign) => campaign.status === status);
    if (brand) result = result.filter((campaign) => campaign.brand === brand);
    setFilteredCampaigns(result);
  };

  const handleAddCampaign = () => {
    if (!newCampaign.name || !newCampaign.brand) {
      alert("Please enter campaign name and brand");
      return;
    }

    const campaignToAdd = {
      id: campaigns.length + 1,
      name: newCampaign.name,
      brand: newCampaign.brand,
      status: "Active",
      image: newCampaign.image || "default-campaign.jpg",
      description: newCampaign.description || "",
      ademptionRate: newCampaign.ademptionRate || 0,
      winPercentage: newCampaign.winPercentage || 0,
      coupon: newCampaign.coupon || "",
      engagement: 0,
      createdOn: "Just now",
    };

    const updatedCampaigns = [...campaigns, campaignToAdd];
    setCampaigns(updatedCampaigns);
    setFilteredCampaigns(updatedCampaigns);
    setNewCampaign({});
    setIsAddDialogOpen(false);
  };

  const handleEditCampaign = (currentCampaign) => {
    if (!currentCampaign.name || !currentCampaign.brand) {
      alert("Please enter campaign name and brand");
      return;
    }

    const updatedCampaigns = campaigns.map((campaign) =>
      campaign.id === currentCampaign.id ? currentCampaign : campaign
    );

    setCampaigns(updatedCampaigns);
    setFilteredCampaigns(updatedCampaigns);
    setCurrentCampaign(null);
    setIsEditDialogOpen(false);
  };

  const handleDeleteCampaign = (campaignId) => {
    const updatedCampaigns = campaigns.filter(
      (campaign) => campaign.id !== campaignId
    );

    setCampaigns(updatedCampaigns);
    setFilteredCampaigns(updatedCampaigns);
  };

  const openEditDialog = (campaign) => {
    setCurrentCampaign({ ...campaign });
    setIsEditDialogOpen(true);
  };

  return (
    <div className="min-h-screen bg-gray-200 dark:bg-gray-900 text-gray-900 dark:text-gray-100 p-6 mx-auto">
      {/* Theme Toggle Button */}
      {/* <div className="fixed top-4 right-4 z-50">
        <button 
          onClick={toggleTheme}
          className="bg-gray-200 dark:bg-gray-700 p-2 rounded-full hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors flex items-center"
        >
          {theme === 'light' ? (
            <>
              <Moon className="mr-2 w-5 h-5" /> Dark
            </>
          ) : (
            <>
              <Sun className="mr-2 w-5 h-5" /> Light
            </>
          )}
        </button>
      </div> */}

      <div className="container mx-auto">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold text-gray-800 dark:text-gray-200 mb-2">
            Campaign Dashboard
          </h1>
          <button
            className="bg-green-700 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300"
            onClick={() => setIsAddDialogOpen(true)}
          >
            Add Campaign
          </button>
        </div>

        <div className="flex flex-col gap-4 mb-6">
          <input
            type="text"
            placeholder="Search Campaigns..."
            value={searchTerm}
            onChange={handleSearch}
            className="p-3 border border-gray-300 dark:border-gray-600 rounded-lg w-full max-w-lg focus:outline-none focus:ring-2 focus:ring-green-800 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
          />

          <div className="flex flex-wrap gap-4">
            <select
              value={statusFilter}
              onChange={handleStatusFilterChange}
              className="p-2 border border-gray-300 dark:border-gray-600 rounded-lg w-full max-w-xs focus:outline-none focus:ring-2 focus:ring-green-800 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
            >
              <option value="">All Status</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>

            <select
              value={brandFilter}
              onChange={handleBrandFilterChange}
              className="p-2 border border-gray-300 dark:border-gray-600 rounded-lg w-full max-w-xs focus:outline-none focus:ring-2 focus:ring-green-800 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
            >
              <option value="">All Brands</option>
              {Array.from(
                new Set(campaigns.map((campaign) => campaign.brand))
              ).map((brand) => (
                <option key={brand} value={brand}>
                  {brand}
                </option>
              ))}
            </select>
          </div>
        </div>

        <CampaignTable
          campaigns={filteredCampaigns}
          onEdit={openEditDialog}
          onDelete={handleDeleteCampaign}
          onDeleteCampaign={handleDeleteCampaign}
          onEditCampaign={handleEditCampaign}
          key={"campaign-table"}
          currentCampaign={currentCampaign}
          setCurrentCampaign={setCurrentCampaign}
          setIsEditDialogOpen={setIsEditDialogOpen}
        />

        <CampaignModel
          isAddDialogOpen={isAddDialogOpen}
          setIsAddDialogOpen={setIsAddDialogOpen}
          newCampaign={newCampaign}
          setNewCampaign={setNewCampaign}
          handleAddCampaign={handleAddCampaign}
        />

        {isEditDialogOpen && (
          <CampaignModel
            isAddDialogOpen={isEditDialogOpen}
            setIsAddDialogOpen={setIsEditDialogOpen}
            newCampaign={currentCampaign}
            setNewCampaign={setCurrentCampaign}
            handleAddCampaign={handleEditCampaign}
            isEditMode={true}
          />
        )}
      </div>
    </div>
  );
};

export default CampaignDashboard;
// CampaignTable.jsx
// import React from 'react';
// import { Pencil, Trash2 } from 'lucide-react';

// const CampaignTable = ({ campaigns, onEdit, onDelete }) => {
//   return (
//     <div className="overflow-x-auto">
//       <table className="w-full bg-white shadow-md rounded-lg overflow-hidden">
//         <thead className="bg-gray-100">
//           <tr>
//             <th className="p-3 text-left">Name</th>
//             <th className="p-3 text-left">Brand</th>
//             <th className="p-3 text-left">Status</th>
//             <th className="p-3 text-left">Engagement</th>
//             <th className="p-3 text-left">Created On</th>
//             <th className="p-3 text-center">Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {campaigns.map((campaign) => (
//             <tr key={campaign.id} className="border-b hover:bg-gray-50">
//               <td className="p-3">{campaign.name}</td>
//               <td className="p-3">{campaign.brand}</td>
//               <td className="p-3">
//                 <span
//                   className={`px-2 py-1 rounded ${
//                     campaign.status === 'Active'
//                     ? 'bg-green-200 text-green-800'
//                     : 'bg-red-200 text-red-800'
//                   }`}
//                 >
//                   {campaign.status}
//                 </span>
//               </td>
//               <td className="p-3">{campaign.engagement}</td>
//               <td className="p-3">{campaign.createdOn}</td>
//               <td className="p-3 flex justify-center space-x-2">
//                 <button
//                   onClick={() => onEdit(campaign)}
//                   className="text-blue-600 hover:text-blue-800"
//                 >
//                   <Pencil size={20} />
//                 </button>
//                 <button
//                   onClick={() => onDelete(campaign.id)}
//                   className="text-red-600 hover:text-red-800"
//                 >
//                   <Trash2 size={20} />
//                 </button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default CampaignTable;

// // CampaignModel.jsx
// import React from 'react';
// import { X } from 'lucide-react';

// const CampaignModel = ({
//   isAddDialogOpen,
//   setIsAddDialogOpen,
//   newCampaign,
//   setNewCampaign,
//   handleAddCampaign,
//   isEditMode = false
// }) => {
//   if (!isAddDialogOpen) return null;

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setNewCampaign(prev => ({ ...prev, [name]: value }));
//   };

//   return (
//     <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
//       <div className="bg-white p-6 rounded-lg w-full max-w-md mx-4">
//         <div className="flex justify-between items-center mb-4">
//           <h2 className="text-xl font-bold">
//             {isEditMode ? 'Edit Campaign' : 'Add New Campaign'}
//           </h2>
//           <button
//             onClick={() => setIsAddDialogOpen(false)}
//             className="text-gray-600 hover:text-gray-900"
//           >
//             <X size={24} />
//           </button>
//         </div>

//         <form onSubmit={(e) => {
//           e.preventDefault();
//           handleAddCampaign();
//         }}>
//           <div className="mb-4">
//             <label className="block text-gray-700 mb-2">Campaign Name</label>
//             <input
//               type="text"
//               name="name"
//               value={newCampaign.name || ''}
//               onChange={handleChange}
//               className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-800"
//               required
//             />
//           </div>

//           <div className="mb-4">
//             <label className="block text-gray-700 mb-2">Brand</label>
//             <input
//               type="text"
//               name="brand"
//               value={newCampaign.brand || ''}
//               onChange={handleChange}
//               className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-800"
//               required
//             />
//           </div>

//           <div className="mb-4">
//             <label className="block text-gray-700 mb-2">Description</label>
//             <textarea
//               name="description"
//               value={newCampaign.description || ''}
//               onChange={handleChange}
//               className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-800"
//             />
//           </div>

//           <div className="flex justify-end space-x-4">
//             <button
//               type="button"
//               onClick={() => setIsAddDialogOpen(false)}
//               className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
//             >
//               Cancel
//             </button>
//             <button
//               type="submit"
//               className="px-4 py-2 bg-green-700 text-white rounded hover:bg-green-600"
//             >
//               {isEditMode ? 'Update Campaign' : 'Add Campaign'}
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default CampaignModel;
