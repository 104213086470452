import React, { useState } from 'react';
import { 
  HelpCircle, 
  Mail, 
  Phone, 
  MessageCircle, 
  ChevronDown, 
  Send 
} from 'lucide-react';

const HelpAndSupport = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [feedback, setFeedback] = useState({
    message: '',
    email: ''
  });

  const faqs = [
    {
      question: "How do I reset my password?",
      answer: "Navigate to Account Settings, click 'Reset Password', and follow the email instructions. Ensure you check your spam folder if you don't receive the reset link."
    },
    {
      question: "What payment methods do you accept?",
      answer: "We accept major credit cards, PayPal, and bank transfers. All transactions are securely processed through our encrypted payment gateway."
    },
    {
      question: "How can I track my order?",
      answer: "Log into your account and navigate to the 'Order History' section. Here you'll find real-time tracking information for all your recent purchases."
    },
    {
      question: "Do you offer international shipping?",
      answer: "Yes, we provide international shipping to most countries. Shipping rates and delivery times vary depending on your location."
    }
  ];

  const contactChannels = [
    {
      icon: <Mail className="text-blue-500" />,
      title: "Email Support",
      description: "support@example.com",
      action: "Send Email"
    },
    {
      icon: <Phone className="text-green-500" />,
      title: "Phone Support",
      description: "+1 (555) 123-4567",
      action: "Call Now"
    },
    {
      icon: <MessageCircle className="text-purple-500" />,
      title: "Live Chat",
      description: "Instant Support Available",
      action: "Start Chat"
    }
  ];

  const handleFeedbackSubmit = (e) => {
    e.preventDefault();
    // Implement feedback submission logic
    console.log("Feedback submitted:", feedback);
    // Reset form or show confirmation
    setFeedback({ message: '', email: '' });
  };

  return (
    <div className="min-h-screen bg-gray-200 dark:bg-gray-900 dark:from-gray-900 dark:to-gray-800 p-6 lg:p-12">
      <div className="max-w-6xl mx-auto">
        {/* Header */}
        <div className="flex items-center mb-10 space-x-4">
          <HelpCircle className="w-12 h-12 text-blue-600" />
          <h1 className="text-4xl font-extrabold text-gray-800 dark:text-white">
            Help & Support Center
          </h1>
        </div>

        {/* Main Content Grid */}
        <div className="grid md:grid-cols-2 gap-8">
          {/* FAQ Section */}
          <div className="bg-white dark:bg-gray-900 rounded-2xl shadow-2xl p-6">
            <h2 className="text-2xl font-bold mb-6 text-gray-800 dark:text-white flex items-center">
              <HelpCircle className="mr-3 text-blue-600" /> Frequently Asked Questions
            </h2>
            <div className="space-y-4">
              {faqs.map((faq, index) => (
                <div 
                  key={index} 
                  className="border-b last:border-b-0 border-gray-200 dark:border-gray-700 pb-4"
                >
                  <div 
                    onClick={() => setActiveAccordion(activeAccordion === index ? null : index)}
                    className="flex justify-between items-center cursor-pointer"
                  >
                    <h3 className="font-semibold text-gray-700 dark:text-gray-300">
                      {faq.question}
                    </h3>
                    <ChevronDown 
                      className={`transform transition-transform duration-300 ${
                        activeAccordion === index ? 'rotate-180' : ''
                      } text-gray-500`} 
                    />
                  </div>
                  {activeAccordion === index && (
                    <p className="mt-2 text-sm text-gray-600 dark:text-gray-400 animate-fade-in">
                      {faq.answer}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* Contact & Feedback Section */}
          <div className="space-y-8">
            {/* Contact Channels */}
            <div className="bg-white dark:bg-gray-900 rounded-2xl shadow-2xl p-6">
              <h2 className="text-2xl font-bold mb-6 text-gray-800 dark:text-white">
                Contact Channels
              </h2>
              <div className="space-y-4">
                {contactChannels.map((channel, index) => (
                  <div 
                    key={index} 
                    className="flex items-center justify-between bg-gray-50 dark:bg-gray-800 p-4 rounded-lg hover:bg-blue-50 dark:hover:bg-gray-700 transition-colors"
                  >
                    <div className="flex items-center space-x-4">
                      {channel.icon}
                      <div>
                        <h3 className="font-semibold text-gray-800 dark:text-white">
                          {channel.title}
                        </h3>
                        <p className="text-sm text-gray-600 dark:text-gray-400">
                          {channel.description}
                        </p>
                      </div>
                    </div>
                    <button className="px-3 py-1 bg-blue-500 text-white rounded-full text-sm hover:bg-blue-600 transition-colors">
                      {channel.action}
                    </button>
                  </div>
                ))}
              </div>
            </div>

            {/* Feedback Form */}
            <div className="bg-white dark:bg-gray-900 rounded-2xl shadow-2xl p-6">
              <h2 className="text-2xl font-bold mb-6 text-gray-800 dark:text-white">
                Share Your Feedback
              </h2>
              <form onSubmit={handleFeedbackSubmit} className="space-y-4">
                <div>
                  <label 
                    htmlFor="email" 
                    className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
                  >
                    Your Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={feedback.email}
                    onChange={(e) => setFeedback({...feedback, email: e.target.value})}
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-700 rounded-lg dark:bg-gray-800 dark:text-white focus:ring-2 focus:ring-blue-500"
                    placeholder="you@example.com"
                    required
                  />
                </div>
                <div>
                  <label 
                    htmlFor="message" 
                    className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
                  >
                    Your Message
                  </label>
                  <textarea
                    id="message"
                    value={feedback.message}
                    onChange={(e) => setFeedback({...feedback, message: e.target.value})}
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-700 rounded-lg dark:bg-gray-800 dark:text-white focus:ring-2 focus:ring-blue-500"
                    rows="4"
                    placeholder="Tell us how we can improve..."
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="w-full flex items-center justify-center space-x-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                >
                  <Send className="w-5 h-5" />
                  <span>Submit Feedback</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpAndSupport;