import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  Users, 
  CreditCard, 
  Award, 
  DollarSign, 
  TrendingUp, 
  Activity,
  Moon,
  Sun
} from 'lucide-react';
import { 
  LineChart, 
  Line, 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  ResponsiveContainer 
} from 'recharts';

// Dashboard Card Component
const DashboardCard = ({ 
  icon: Icon, 
  title, 
  value, 
  change, 
  changeType = 'positive',
  backgroundColor = 'bg-white dark:bg-gray-800',
  textColor = 'text-gray-800 dark:text-gray-200'
}) => {
  return (
    <div className={`${backgroundColor} rounded-2xl shadow-lg p-3 transform transition-all duration-300 hover:scale-105 hover:shadow-xl`}>
      <div className="flex justify-between items-center mb-4">
        <div className={`p-3 rounded-full ${changeType === 'positive' ? 'bg-green-100 dark:bg-green-900/30' : 'bg-red-100 dark:bg-red-900/30'}`}>
          <Icon className={`${changeType === 'positive' ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'} w-6 h-6`} />
        </div>
        <div className={`text-sm font-medium ${changeType === 'positive' ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>
          {change}
        </div>
      </div>
      <div>
        <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-1">{title}</h3>
        <div className={`text-2xl font-bold ${textColor}`}>{value}</div>
      </div>
    </div>
  );
};

// Chart Component
const ChartSection = () => {
  const userGrowthData = [
    { name: 'Jan', users: 400 },
    { name: 'Feb', users: 300 },
    { name: 'Mar', users: 500 },
    { name: 'Apr', users: 450 },
    { name: 'May', users: 600 },
    { name: 'Jun', users: 550 }
  ];

  const revenueData = [
    { name: 'Jan', revenue: 4000 },
    { name: 'Feb', revenue: 3000 },
    { name: 'Mar', revenue: 5000 },
    { name: 'Apr', revenue: 4500 },
    { name: 'May', revenue: 6000 },
    { name: 'Jun', revenue: 5500 }
  ];

  return (
    <div className="grid md:grid-cols-2 gap-6 mt-8">
      <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6">
        <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-4">User Growth</h3>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={userGrowthData}>
            <CartesianGrid 
              strokeDasharray="3 3" 
              stroke="gray" 
              className="opacity-20 dark:opacity-30" 
            />
            <XAxis 
              dataKey="name" 
              className="text-gray-600 dark:text-gray-300" 
            />
            <YAxis 
              className="text-gray-600 dark:text-gray-300" 
            />
            <Tooltip 
              contentStyle={{ 
                backgroundColor: 'rgb(31, 41, 55)', 
                color: 'white',
                borderRadius: '0.5rem' 
              }} 
            />
            <Line 
              type="monotone" 
              dataKey="users" 
              stroke="#8884d8" 
              strokeWidth={3}
              dot={{ r: 6 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6">
        <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-4">Monthly Revenue</h3>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={revenueData}>
            <CartesianGrid 
              strokeDasharray="3 3" 
              stroke="gray" 
              className="opacity-20 dark:opacity-30" 
            />
            <XAxis 
              dataKey="name" 
              className="text-gray-600 dark:text-gray-300" 
            />
            <YAxis 
              className="text-gray-600 dark:text-gray-300" 
            />
            <Tooltip 
              contentStyle={{ 
                backgroundColor: 'rgb(31, 41, 55)', 
                color: 'white',
                borderRadius: '0.5rem' 
              }} 
            />
            <Bar 
              dataKey="revenue" 
              fill="#82ca9d" 
              radius={[10, 10, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

// Main Dashboard Component
const Dashboard = () => {
  const location = useLocation()
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [theme, setTheme] = useState(
    localStorage.getItem('theme') || 
    (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')
  );

  // Authentication Check
 
  useEffect(() => {
    const checkAuth = () => {
      // const isLoggedIn = localStorage.getItem('login') === 'true';
      // if (!isLoggedIn) {
        if(!location.state?.flag){navigate('/');
          return null
        }
        
      // }
      setIsLoading(false);
    };

    checkAuth();
  }, [navigate]);

  // Theme Application Effect
  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
    localStorage.setItem('theme', theme);
  }, [theme]);

  // Theme Toggle Function
  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };
 if(!location.state?.flag){navigate('/welcome');
    return null
  }
  // Dashboard Statistics
  const dashboardStats = [
    {
      icon: Users,
      title: 'Total Users',
      value: '5,241',
      change: '+12.5%',
      changeType: 'positive',
      backgroundColor: 'bg-blue-50 dark:bg-blue-900/20',
      textColor: 'text-blue-800 dark:text-blue-300'
    },
    {
      icon: CreditCard,
      title: 'Active Subscriptions',
      value: '1,024',
      change: '-3.2%',
      changeType: 'negative',
      backgroundColor: 'bg-purple-50 dark:bg-purple-900/20',
      textColor: 'text-purple-800 dark:text-purple-300'
    },
    {
      icon: Award,
      title: 'Total Rewards',
      value: '512',
      change: '+8.7%',
      changeType: 'positive',
      backgroundColor: 'bg-green-50 dark:bg-green-900/20',
      textColor: 'text-green-800 dark:text-green-300'
    },
    {
      icon: DollarSign,
      title: 'Revenue',
      value: '$42,567',
      change: '+15.3%',
      changeType: 'positive',
      backgroundColor: 'bg-yellow-50 dark:bg-yellow-900/20',
      textColor: 'text-yellow-800 dark:text-yellow-300'
    },
    {
      icon: TrendingUp,
      title: 'Growth Rate',
      value: '24.6%',
      change: '+5.1%',
      changeType: 'positive',
      backgroundColor: 'bg-teal-50 dark:bg-teal-900/20',
      textColor: 'text-teal-800 dark:text-teal-300'
    },
    {
      icon: Activity,
      title: 'User Engagement',
      value: '76.4%',
      change: '-2.3%',
      changeType: 'negative',
      backgroundColor: 'bg-red-50 dark:bg-red-900/20',
      textColor: 'text-red-800 dark:text-red-300'
    }
  ];

  // Loading State
  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-200">
        <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-200 dark:bg-gray-900 text-gray-900 dark:text-gray-100">
      {/* Theme Toggle Button */}
      {/* <div className="fixed top-4 right-4 z-50">
        <button 
          onClick={toggleTheme}
          className="bg-gray-200 dark:bg-gray-700 p-2 rounded-full hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors flex items-center"
        >
          {theme === 'light' ? (
            <>
              <Moon className="mr-2 w-5 h-5" /> Dark
            </>
          ) : (
            <>
              <Sun className="mr-2 w-5 h-5" /> Light
            </>
          )}
        </button>
      </div> */}

      {/* Dashboard Content */}
      <div className="container mx-auto px-4 py-8">
        {/* Header */}
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-gray-800 dark:text-gray-200 mb-2">Dashboard</h1>
          <p className="text-gray-600 dark:text-gray-400">Welcome back! Here's an overview of your platform.</p>
        </div>

        {/* Statistics Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {dashboardStats.map((stat, index) => (
            <DashboardCard 
              key={index}
              icon={stat.icon}
              title={stat.title}
              value={stat.value}
              change={stat.change}
              changeType={stat.changeType}
              backgroundColor={stat.backgroundColor}
              textColor={stat.textColor}
            />
          ))}
        </div>

        {/* Charts Section */}
        <ChartSection />
      </div>
    </div>
  );
};

export default Dashboard;