import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { AlertTriangle, Home, RefreshCw } from 'lucide-react';

const AnimatedErrorPage = () => {
  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        damping: 12,
        stiffness: 100,
      },
    },
  };

  const errorIconVariants = {
    initial: { rotate: 0 },
    animate: {
      rotate: [0, 15, -15, 0],
      transition: {
        duration: 0.5,
        repeat: Infinity,
        ease: 'easeInOut',
      },
    },
  };

  // Function to generate bubble elements
  const renderBubbles = () => {
    const bubbles = Array.from({ length: 20 }, (_, i) => (
      <motion.div
        key={i}
        initial={{
          opacity: 0,
          x: Math.random() * 200 - 100,
          y: Math.random() * 200 - 100,
          scale: Math.random() * 0.5 + 0.5,
        }}
        animate={{
          opacity: [0.3, 0.7, 0.3],
          x: [Math.random() * 400 - 200, Math.random() * 400 - 200],
          y: [Math.random() * 400 - 200, Math.random() * 400 - 200],
        }}
        transition={{
          duration: Math.random() * 8 + 4,
          repeat: Infinity,
          repeatType: 'reverse',
          ease: 'easeInOut',
        }}
        className="absolute rounded-full bg-blue-300 blur-lg"
        style={{
          width: `${Math.random() * 60 + 20}px`,
          height: `${Math.random() * 60 + 20}px`,
          top: `${Math.random() * 100}vh`,
          left: `${Math.random() * 100}vw`,
        }}
      />
    ));
    return bubbles;
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 flex items-center justify-center p-4 relative overflow-hidden">
      {/* Background Bubble Animation */}
      {renderBubbles()}

      {/* Main card */}
      <motion.div
        initial="hidden"
        animate="visible"
        variants={containerVariants}
        className="max-w-lg w-full bg-white shadow-2xl rounded-3xl overflow-hidden"
      >
        <motion.div variants={itemVariants} className="p-8 text-center">
          <motion.div
            variants={errorIconVariants}
            animate="animate"
            className="flex justify-center mb-6"
          >
            <div className="bg-red-100 rounded-full p-6">
              <AlertTriangle
                className="text-red-500"
                size={72}
                strokeWidth={1.5}
              />
            </div>
          </motion.div>

          <motion.h1
            variants={itemVariants}
            className="text-4xl font-bold text-gray-800 mb-4"
          >
            404 - Page Not Found
          </motion.h1>

          <motion.p variants={itemVariants} className="text-gray-600 mb-6">
            We can’t seem to find the page you’re looking for. It might have
            been moved or deleted.
          </motion.p>

          <motion.div
            variants={itemVariants}
            className="flex justify-center space-x-4"
          >
            <Link
              to="/"
              className="flex items-center bg-indigo-500 text-white px-5 py-2 rounded-lg hover:bg-indigo-600 transition duration-300 ease-in-out"
            >
              <Home className="mr-2" size={20} />
              Go Home
            </Link>
            <button
              onClick={() => window.location.reload()}
              className="flex items-center bg-green-500 text-white px-5 py-2 rounded-lg hover:bg-green-600 transition duration-300 ease-in-out"
            >
              <RefreshCw className="mr-2" size={20} />
              Reload
            </button>
          </motion.div>
        </motion.div>

        <motion.div
          variants={itemVariants}
          className="bg-gray-50 p-4 text-center"
        >
          <p className="text-xs text-gray-500">
            Need assistance? Reach out to our support team anytime.
          </p>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default AnimatedErrorPage;
