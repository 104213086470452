import React from "react";
import { useTheme } from "./ThemeContext";
import { BellIcon } from "lucide-react";
import ProfileEditor from "../pages/ProfileEditor";

const Header = () => {
  const { theme, setTheme } = useTheme();

  return (
    <div className={`${theme === "dark" ? "bg-gray-900 text-white" : "bg-white text-gray-800"}`}>
      <header 
        className={`flex items-center justify-end px-8 shadow-lg p-2 gap-4 border-b 
        ${theme === "dark" 
          ? "bg-gray-800 border-gray-700 shadow-inner shadow-green-300" 
          : "bg-blue-100 border-gray-100 shadow-inner shadow-blue-300"}`}
      >
        <button 
          onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
          className={`relative hover:opacity-80 transition-opacity rounded-full p-1 
          ${theme === "dark" 
            ? "text-green-400 border-2 border-green-400" 
            : "text-[#3E782A] border-2 border-[#3E782A]"}`}
        >
          <BellIcon />
        </button>
        
        <ProfileEditor />
      </header>
    </div>
  );
};

export default Header;