import { useState } from 'react';
import { IoIosSwitch, IoMdCall, IoIosPeople, IoMdHeart, IoMdLogIn } from 'react-icons/io';

export const EmployeeDetailsModal = ({ employee, onClose }) => {
     const [activeStatus, setActiveStatus] = useState(employee.status);
   
     const handleToggleStatus = () => {
       setActiveStatus((prevStatus) => (prevStatus === "Active" ? "Inactive" : "Active"));
     };
   
     return (
       <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4">
         <div className="bg-white dark:bg-gray-900 rounded-xl shadow-2xl max-w-md w-full p-6 relative">
           <button 
             onClick={onClose} 
             className="absolute top-4 right-4 text-gray-500 hover:text-gray-800"
           >
             ✕
           </button>
           
           <div className="flex items-center mb-6">
             <img 
               src={employee.avatar} 
               alt={employee.name} 
               className="w-16 h-16 rounded-full mr-4 " 
             />
             <div>
               <h2 className="text-xl font-bold ">{employee.name}</h2>
               <p className="text-gray-600">{employee.position}</p>
             </div>
           </div>
   
           <div className="space-y-4">
             {/* Combined Status and Department section, but each in a separate container */}
             <div className="flex justify-between items-center space-x-4">
               {/* Status container */}
               <div className="bg-gray-100 dark:bg-gray-700 p-3 rounded-lg flex items-center gap-2 w-1/2">
                 <div className="flex flex-col items-center gap-2">
                   <div className="flex gap-2">
                   <IoIosSwitch className="w-5 h-5 dark:text-white" />
                   <p className="text-sm dark:text-white">Status</p>
                   </div>
                   <span className={`font-semibold ${activeStatus === "Active" ? "text-green-600" : "text-red-600"} mr-4`}>
                     {activeStatus}
                   </span>
                 </div>
   
                 {/* Status text (Active/Inactive) below the label */}
                
   
                 {/* Switch button positioned at the top-right end */}
                 <label className="flex items-center cursor-pointer ml-auto">
                   <div className="relative">
                     <input
                       type="checkbox"
                       checked={activeStatus === "Active"}
                       onChange={handleToggleStatus}
                       className="sr-only"
                     />
                     <div className="w-12 h-6 bg-green-600 relative bottom-4 rounded-full transition-all"></div>
                     <div
                       className={`absolute bottom-5 left-1 w-4 h-4 bg-white rounded-full transition-transform transform ${
                         activeStatus === "Active" ? "translate-x-6 bg-green-600" : "translate-x-0 bg-red-600"
                       }`}
                     ></div>
                   </div>
                 </label>
               </div>
   
               {/* Department container */}
               <div className="bg-gray-100 dark:bg-gray-700 p-7 rounded-lg w-1/2">
                 <div className="flex items-center gap-2">
                   <IoIosPeople className="w-5 h-5 dark:text-white" />
                   <p className="text-sm dark:text-white">Department</p>
                 </div>
                 <p className="font-semibold">{employee.department}</p>
               </div>
             </div>
   
             {/* Additional information grid */}
             <div className="grid grid-cols-2 gap-4">
               {/* Phone container */}
               <div className="bg-gray-100 dark:bg-gray-700 p-3 rounded-lg">
                 <div className="flex items-center gap-2">
                   <IoMdCall className="w-5 h-5 dark:text-white" />
                   <p className="text-sm dark:text-white">Phone</p>
                 </div>
                 <p className="font-semibold">{employee.phone}</p>
               </div>
   
               {/* Last Activity container */}
               <div className="bg-gray-100 dark:bg-gray-700 p-3 rounded-lg">
                 <div className="flex items-center gap-2">
                   <IoMdHeart className="w-5 h-5 dark:text-white" />
                   <p className="text-sm dark:text-white">Last Activity</p>
                 </div>
                 <p className="font-semibold">{employee.activity}</p>
               </div>
   
               {/* Win Count container */}
               <div className="bg-gray-100 dark:bg-gray-700 p-3 rounded-lg">
                 <div className="flex items-center gap-2">
                   <IoMdHeart className="w-5 h-5 dark:text-white" />
                   <p className="text-sm dark:text-white">Win Count</p>
                 </div>
                 <p className="font-semibold">30</p>
               </div>
   
               {/* Redemption Count container */}
               <div className="bg-gray-100 dark:bg-gray-700 p-3 rounded-lg">
                 <div className="flex items-center gap-2">
                   <IoMdHeart className="w-5 h-5 dark:text-white" />
                   <p className="text-sm dark:text-white">Redemption Count</p>
                 </div>
                 <p className="font-semibold">70</p>
               </div>
   
               {/* Login Count container */}
               <div className="bg-gray-100 dark:bg-gray-700 p-3 rounded-lg">
                 <div className="flex items-center gap-2">
                   <IoMdLogIn className="w-5 h-5 dark:text-white" />
                   <p className="text-sm dark:text-white">Login Count</p>
                 </div>
                 <p className="font-semibold">10</p>
               </div>
             </div>
           </div>
   
           <div className="mt-6 flex justify-end">
             <button 
               onClick={onClose} 
               className="px-6 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
             >
               Close
             </button>
           </div>
         </div>
       </div>
     );
   };

   